import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import AniLink from "gatsby-plugin-transition-link/AniLink"
//import ImageWithAnimation from "../components/ImageWithAnimation"
import { textAppearAnimationAll } from "../components/textAppearAnimation"
import TextRepeater from "../components/TextRepeater"
import { Draggable } from "gsap/Draggable"
import { InertiaPlugin } from "gsap/InertiaPlugin"

import { gsap } from "gsap"
import VideoEmbed from "../components/VideoEmbed"

let draggableClientType = false
let draggableWorkType = false

const Work = ({ data }) => {

  const [selectedClientType, setClientType] = useState("all")
  const [selectedClientHover, setClientHover] = useState("all")

  const [selectedWorkType, setWorkType] = useState("all")
  const [selectedWorkHover, setWorkHover] = useState("all")

  const posts = data.allWpWork.nodes

  const clientType = data.allWpClientType.nodes
  const workType = data.allWpWorkType.nodes

  const initClientType = () => {

    let points = [0]
    let slug = ["all"]

    clientType.map(client => {
      slug.push(client.slug)
      return true
    })

    let margin = 48
    if (window.innerWidth > 560) {
      margin = 64
      if (window.innerWidth > 1128) {
        margin = 80
      }
    }

    const div = document.querySelector("#scroll-client-type")
    const div_li = div.querySelectorAll("li")
    let div_li_last_position = 0
    div_li.forEach((item, index) => {
      const next_position = Math.round(div_li_last_position + item.getBoundingClientRect().width + margin)
      points.push(next_position)
      div_li_last_position = next_position
    })
    points.pop() //remove last elements

    draggableClientType = Draggable.create("#scroll-client-type", {
      type: "scrollLeft",
      edgeResistance: 0.6,
      lockAxis: true,
      throwProps: true,
      inertia: true,
      maxDuration: 0.4,
      minDuration: 0.4,
      snap: {
        x: points
      },
      onThrowUpdate: function() {
        let selectedType = false
        const wrapper_left = -Math.round(this.x)
        points.forEach((pos, index) => {
          if (pos > wrapper_left - (margin / 2) && pos < wrapper_left + (margin / 2)) {
            selectedType = slug[index]
          }
        })
        setClientHover(selectedType)
      },
      onThrowComplete: function() {
        const wrapper_left = -Math.round(this.x)
        let selectedType = selectedWorkType
        points.forEach((pos, index) => {
          if (pos === wrapper_left) {
            selectedType = slug[index]
          }
        })
        setClientType(selectedType)
      }
    })
  }


  const initWorkType = () => {

    let points = [0]
    let slug = ["all"]

    workType.map(work => {
      slug.push(work.slug)
      return true
    })

    let margin = 48
    if (window.innerWidth > 560) {
      margin = 64
      if (window.innerWidth > 1128) {
        margin = 80
      }
    }

    const div = document.querySelector("#scroll-work-type")
    const div_li = div.querySelectorAll("li")
    let div_li_last_position = 0
    div_li.forEach((item, index) => {
      const next_position = Math.round(div_li_last_position + item.getBoundingClientRect().width + margin)
      points.push(next_position)
      div_li_last_position = next_position
    })
    points.pop() //remove last elements

    draggableWorkType = Draggable.create("#scroll-work-type", {
      type: "scrollLeft",
      edgeResistance: 0.6,
      lockAxis: true,
      throwProps: true,
      inertia: true,
      maxDuration: 0.4,
      minDuration: 0.4,
      snap: {
        x: points
      },
      onThrowUpdate: function() {
        let selectedType = false
        const wrapper_left = -Math.round(this.x)
        points.forEach((pos, index) => {
          if (pos > wrapper_left - (margin / 2) && pos < wrapper_left + (margin / 2)) {
            selectedType = slug[index]
          }
        })
        setWorkHover(selectedType)
      },
      onThrowComplete: function() {
        const wrapper_left = -Math.round(this.x)
        let selectedType = selectedClientType
        points.forEach((pos, index) => {
          if (pos === wrapper_left) {
            selectedType = slug[index]
          }
        })
        setWorkType(selectedType)
      }
    })
  }

  const handleResize = () => {

    // console.log("handleResize")

    if (draggableClientType) {
      draggableClientType[0].kill()
    }
    if (draggableWorkType) {
      draggableWorkType[0].kill()
    }

    initClientType()
    initWorkType()
  }

  useEffect(() => {
    textAppearAnimationAll("h3")

    gsap.registerPlugin(Draggable, InertiaPlugin)

    initClientType()
    initWorkType()

    window.addEventListener("resize", handleResize)
  }, [])

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="Work" />
        <Bio />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout page={"work"}>
      <Seo title="Portfolio" />

      <div className={"padding-bottom-64"}>
        <div className={"filters"}>
          <h4 className="padding-bottom-16">Filter work by industry:</h4>
          <ol className={"filter"} id={"scroll-client-type"}>
            <li className={selectedClientHover === "all" ? "active" : ""}>All industries
              <div className={"count"}>{posts.length}</div>
            </li>
            {clientType.map(client => {
              return (
                <li key={client.slug} className={selectedClientHover === client.slug ? "active" : ""}>
                  {client.name}
                  <div className={"count"}>
                    {posts.filter((node) => {
                      if (node.clientTypes.nodes.length) {
                        for (const slug of node.clientTypes.nodes) {
                          if (slug.slug === client.slug) {
                            return true
                          }
                        }
                      }
                      return false
                    }).length}
                  </div>
                </li>
              )
            })}
          </ol>


          <h4 className="padding-bottom-16">Filter work by type:</h4>
          <ol className={"filter"} id={"scroll-work-type"}>
            <li className={selectedWorkType === "all" ? "active" : ""}>All types
              <div className={"count"}>{posts.length}</div>
            </li>
            {workType.map(work => {
              return (
                <li key={work.slug} className={selectedWorkHover === work.slug ? "active" : ""}>
                  {work.name}
                  <div className={"count"}>
                    {posts.filter((node) => {
                      if (node.workTypes.nodes.length) {
                        for (const slug of node.workTypes.nodes) {
                          if (slug.slug === work.slug) {
                            return true
                          }
                        }
                      }
                      return false
                    }).length}
                  </div>
                </li>
              )
            })}
          </ol>
        </div>
      </div>

      <ol className={"work-list padding-bottom-200"}>
        {posts.filter((node) => {

          let foundWork = true
          let foundClient = true

          if (selectedClientType && selectedClientType !== "all") {
            foundClient = false
            if (node.clientTypes.nodes.length) {
              for (const slug of node.clientTypes.nodes) {
                if (slug.slug === selectedClientType) {
                  foundClient = true
                }
              }
            }
          }

          if (selectedWorkType && selectedWorkType !== "all") {
            foundWork = false
            if (node.workTypes.nodes.length) {
              for (const slug of node.workTypes.nodes) {
                if (slug.slug === selectedWorkType) {
                  foundWork = true
                }
              }
            }
          }

          return !(!foundWork || !foundClient)

        }).map((post, index) => {
          // const title = post.title
          let services = ""
          post.customFieldsWork.services?.map((item) => {
            services += item.service + " "
            return services
          })
          return (
            <li key={post.uri}>
              <AniLink cover to={post.uri} bg="#000" direction="up" duration={1}>
                <div className={"item wp-block-image link"}>
                  <VideoEmbed video={post.customFieldsWorkLayout.thumbnailVideo} index={index} />
                  <div className={"desc"}>
                    <h3 className={"title"}>{post.customFieldsWork.client}</h3>
                    <TextRepeater text={services} />
                  </div>
                  <div className={"click-area"}
                       id={"click-area-" + post.customFieldsWorkLayout.thumbnailVideo + "-" + index} />
                </div>
              </AniLink>
            </li>
          )
        })}
      </ol>

    </Layout>
  )
}

export default Work

export const pageQuery = graphql`
    query WorkPostArchive {
        allWpWork(
            sort: { fields: [date], order: DESC }
            limit: 999
        ) {
            nodes {
                uri
                title
                customFieldsWorkLayout {
                    thumbnailVideo
                }
                customFieldsWork {
                    client
                    services {
                        service
                    }
                }
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                fluid(maxHeight: 440) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
                workTypes {
                    nodes {
                        name
                        slug
                    }
                }
                clientTypes {
                    nodes {
                        name
                        slug
                    }
                }
            }
        },

        allWpWorkType {
            nodes {
                name
                slug
            }
        },

        allWpClientType {
            nodes {
                name
                slug
            }
        }


    }
`
