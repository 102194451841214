import React from "react"

const TextRepeater = ({text, repeat = 10, speed = 40, way = "left"}) => {

  const className = 'rl_' + (Math.random() + 1).toString(36).substring(7);
  let output = '';
  for(let i = 0; i < repeat; i++){
    output += text + ' ';
  }

  return (
    <div className="wrapperRollingText">
      <div className={`rollingText ${className}`} data-rolling-speed={speed} data-rolling-way={way}>
        {output}
      </div>
      <div className="mobile-only">
        {text}
      </div>
    </div>
  )
}

export default TextRepeater;
