import React, { useEffect, useState } from "react"
import Player from "@vimeo/player"
import { isBrowser, isMobile } from "react-device-detect"
import StopAllVideos from "./functionStopAllVideos"

const VideoEmbed = ({ video, index }) => {

  const [initialize, setInitialize] = useState(false)

  useEffect(() => {
    if (!initialize) {

      setInitialize(true)
      let iframe = document.querySelector("#vimeo-video-embed-" + video + "-" + index)

      const storeSRC = iframe.dataset.src
      delete iframe.dataset.src
      iframe.src = storeSRC

      let player = new Player(iframe)
      player.ready().catch(function(error) {
        console.log(error)
      })
      player.on("error", e => console.log(e))

      if (player) {
        if (isBrowser) {
          const followArea = document.querySelectorAll("#click-area-" + video + "-" + index)
          followArea.forEach(function(el) {
            el.addEventListener("mouseover", (e) => {
              StopAllVideos(video)
              if (player) {
                if (player.ready()) {
                  player.getPaused().then(function(paused) {
                    if (paused) {
                      player.play()
                    }
                  }).catch(function(error) {
                    console.log(error)
                  })
                }
              }
            })

            el.addEventListener("mouseout", (e) => {
              StopAllVideos(video)
            })
          })
        } else {
          player.play();
        }
      }

    }
  }, [index, initialize, video])


  return (
    <div id={"video-hover-" + video + "-" + index}>
      <iframe
        title={"vimeo player"}
        id={"vimeo-video-embed-" + video + "-" + index}
        data-src={"https://player.vimeo.com/video/" + video + "?autoplay=" + (isMobile ? "1" : "0") + "&muted=1&loop=1&autopause=0&title=0&playsinline=1&portrait=1&controls=0"}
        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
        frameBorder="0"
        webkitallowfullscreen={"true"} mozallowfullscreen={"true"} allowFullScreen
        allow="autoplay; fullscreen"
        className={"vimeo-player"}
        data-vimeo-id={video}
      />
    </div>
  )
}

export default VideoEmbed
